export default {
    "app": {
        "texts": {
            "localeText": "Idioma",
            "yes": "Sí",
            "no": "No",
            "success": "Éxito",
            "warning": "Advertencia",
            "error": "Error",
            "back": "Volver",
            "backLogin": "Volver a Iniciar Sesión",
            "close": "Cerrar",
            "save": "Guardar",
            "saveData": "Guardar Datos",
            "dataSaved": "Datos guardados exitosamente",
            "change": "Cambiar",
            "cancel": "Cancelar",
            "confirm": "Confirmar",
            "sendData": "Enviar Datos",
            "selectSinglePlaceholder": "Seleccionar...",
            "selectPlaceholder": "Seleccione una opción...",
            "selectEmptyMessage": "No hay opciones disponibles en este momento",
            "listEmptyMessage": "No se encontraron registros",
            "defaultLoading": "Cargando datos",
            "confirmActionQuestion": "¿Realmente desea realizar esta acción?",
            "confirmActionText": "Sí, continuar",
            "routePermissionDenied": "Parece que intentó acceder a una página restringida",
            "apiHttpSessionExpired": "Su sesión ha expirado, inicie sesión nuevamente",
            "apiHttpError": "Se produjo un error al realizar esta operación, inténtelo de nuevo más tarde",
            "apiHttpTimeout": "Su solicitud está tardando demasiado, inténtelo de nuevo más tarde",
            "apiHttpMaintenance": "El sistema está en proceso de actualización, por favor inténtelo de nuevo más tarde"
        },
        "domains": {
            "auth": {
                "routes": {
                    "login": {
                        "path": "/login/:hash?",
                        "title": "Iniciar Sesión"
                    },
                    "trouble": {
                        "path": "/login/recuperar",
                        "title": "Problemas para acceder a mi cuenta"
                    }
                },
                "components": {
                    "trouble": {
                        "pageTitle": "Problemas para acceder a mi cuenta",
                        "recoveryAccessValidationMessage": "Ingrese su correo electrónico / número de teléfono",
                        "recoveryAccessToEmailMessage": "Hemos enviado un correo electrónico con un enlace para recuperar el acceso a su cuenta",
                        "recoveryAccessToPhoneMessage": "Hemos enviado un SMS con un enlace para recuperar el acceso a su cuenta",
                        "label": "Seleccione una opción",
                        "recoveryOptionsText": {
                            "forgetPassword": {
                                "title": "Recuperar Contraseña",
                                "text": "Olvidé mi contraseña y quiero crear una nueva"
                            },
                            "magicLink": {
                                "title": "Enlace de Acceso Rápido",
                                "text": "Quiero recibir un enlace de inicio de sesión rápido"
                            },
                            "resendActivationCode": {
                                "title": "Reenviar Código de Activación",
                                "text": "Necesito activar mi cuenta"
                            }
                        },
                        "recoveryTextLabel": "Su correo electrónico o número de teléfono"
                    },
                    "login": {
                        "pageTitle": "",
                        "defaultLoginMessage": "Estamos verificando sus datos",
                        "facebookAuth": "Iniciar sesión con Facebook",
                        "googleAuth": "Iniciar sesión con Google",
                        "orLoginDataMessage": "O inicie sesión con sus datos",
                        "form": {
                            "loginLabel": "Correo electrónico / número de teléfono / CPF / DNI / CI",
                            "passwordLabel": "Ingrese su contraseña",
                            "loginBtn": "Iniciar Sesión"
                        },
                        "recoveryAccessBtn": "Problemas para acceder a mi cuenta",
                        "userRegisterBtn": "Crear mi cuenta",
                        "accessWithoutAuth": "Acceso sin registro"
                    }
                }
            },
            "payment": {
                "routes": {
                    "index": {
                        "path": "/payment/:hash?",
                        "title": "Pago"
                    }
                }
            },
            "common": {
                "routes": {
                    "termsConditions": {
                        "path": "/termos-e-condicoes",
                        "title": "Términos y Condiciones"
                    },
                    "privacyPolicies": {
                        "path": "/politicas-de-privacidade",
                        "title": "Políticas de Privacidad"
                    },
                    "contact": {
                        "path": "/fale-conosco",
                        "title": "Contáctenos"
                    }
                },
                "components": {
                    "termsConditions": {
                        "pageTitle": "Términos y Condiciones"
                    },
                    "privacyPolicies": {
                        "pageTitle": "Políticas de Privacidad"
                    },
                    "contact": {
                        "pageTitle": "Contáctenos"
                    }
                }
            },
            "home": {
                "routes": {
                    "home": {
                        "path": "/",
                        "title": "Gripo"
                    }
                },
                "components": {
                    "pageTitle": "",
                    "scheduleMessage": "¿Buscas un horario para jugar? ¡Aquí encontrarás clubes y horarios disponibles!",
                    "scheduleBtn": "Reservar Horario",
                    "searchingStagesMessage": "Buscando torneos",
                    "availableStagesText": "Próximos Torneos",
                    "filterText": "Filtrar",
                    "registrationOpenedText": "Inscripciones abiertas",
                    "finishedStagesText": "Torneos Finalizados",
                    "finishedStagesSubText": "Sigue los resultados de los torneos pasados",
                    "stageCardDatesFromText": "DESDE",
                    "stageCardDatesToText": "HASTA",
                    "stageCoverText": "Cartel/Banner",
                    "contactText": "Contacto",
                    "newStageRegistrationBtn": "Registro",
                    "registrationClosedText": "Registro Cerrado",
                    "showStageRegistrations": "Inscritos",
                    "showStageGroups": "Grupos",
                    "showStageMatches": "Partidos",
                    "searchingAvailableStagesMessage": "Estamos buscando torneos disponibles...",
                    "availableStagesNotFoundMessage": "Actualmente no hay torneos disponibles",
                    "availableStagesWithFiltersNotFoundMessage": "No encontramos torneos con los filtros seleccionados, verifica los filtros seleccionados",
                    "availableStagesNotFoundDoRegisterMessage": "Puedes registrarte mientras no tengamos torneos disponibles.",
                    "doRegisterBtn": "Registrarse",
                    "hasAccountBtn": "Ya tengo cuenta",
                    "contactCardTitle": "¿Necesitas ayuda?",
                    "contactCardText": "Contáctanos por correo electrónico si necesitas soporte o tienes alguna pregunta sobre la aplicación.",
                    "clubCardTitle": "¿Quieres registrar tu club en Gripo?",
                    "clubCardText": "Solicita el registro de prueba en nuestro sitio web a continuación y disfruta del mejor y más grande sistema de gestión deportiva de Brasil",
                    "filters": {
                        "yearLabel": 'Año',
                        "typeLabel": "Deporte",
                        "countryLabel": "País",
                        "stateLabel": "Estado/Provincia",
                        "cityLabel": "Ciudad"
                    }
                }
            },
            "registration": {
                "routes": {
                    "store": {
                        "path": "/torneo/:slug/registro",
                        "title": "Nuevo Registro"
                    },
                    "index": {
                        "path": "/registros",
                        "title": "Mis Registros"
                    }
                },
                "components": {
                    "form": {
                        "pageTitle": "Nuevo Registro",
                        "form": {
                            "stageLabel": "Torneo",
                            "playerClassLabel": "Selecciona la Categoría",
                            "playerClassSelectedLabel": 'Categoria',
                            "countryLabel": "País",
                            "cityLabel": "Ciudad",
                            "cityTypeLabel": "Introduzca el nombre de la ciudad",
                            "citiesEmptyList": "Ciudades no encontradas",
                            "paymentPairText": 'Valor de inscripción por atleta',
                            "paymentUniqueText": 'Valor de inscripción por pareja',
                            "registrationLabel": 'Valor',
                            "loadingAmountLabel": 'Cargando valor de inscripción...',
                            "playerTitle": "Atleta {number}",
                            "playerTitleSingle": "Datos del Atleta",
                            "cpfLabel": "CPF",
                            "cpfEqualMessage": "El CPF del atleta 1 es igual al del atleta 2",
                            "ciLabel": "CI",
                            "dniLabel": "DNI",
                            "firstNameLabel": "Nombre",
                            "firstNamePlaceholder": "Nombre",
                            "lastNameLabel": "Apellido",
                            "lastNamePlaceholder": "Apellido",
                            "emailLabel": "Correo Electrónico",
                            "emailPlaceholder": "Correo Electrónico",
                            "phoneLabel": "Teléfono Celular",
                            "phonePlaceholder": "Teléfono Celular",
                            "sexLabel": "Género",
                            "sexPlaceholder": "Género",
                            "shirtLabel": "Camiseta",
                            "shirtTypeLabel": "Tipo de Camiseta",
                            "shirtTypePlaceholder": "Tipo de Camiseta",
                            "shirtSizeLabel": "Talla de Camiseta",
                            "shirtSizePlaceholder": "Talla de Camiseta",
                            "amountLabel": "Valor del Registro",
                            "contactTitle": "Dudas/Contacto",
                            "registrationConfirmationBtn": 'Ir a Confirmación',
                            "registrationBtn": "Confirmar Registro",
                            "registrationFinished": {
                                "titleSuccess": '¡Todo ha salido bien hasta ahora! Buena suerte en el torneo 💪🏻',
                                "titlePaymentExpired": 'Tu pago ha caducado, ¡regístrate nuevamente!',
                                "contact": '<strong>En caso de dudas:</strong> {contact}',
                                "btnShowMyRegistrations": 'Ver mi registro',
                                "btnGoToPayment": 'Ir para o Pagamento',
                                "btnTryAgain": 'Tentar novamente',
                            },
                            "registrationWithoutPartner": "Registro sin pareja",
                        },
                        "impediments": {
                            "title": "Impedimentos",
                            "message": "Solo puedes seleccionar {value} impedimento(s) en este torneo"
                        },
                        "regulation": {
                            "title": "Consulta el reglamento del torneo",
                            "btnTitle": "Abrir Reglamento",
                            "btnAction": "Haz clic aquí"
                        },
                        "sponsors": {
                            "masterText": "Patrocinador Principal",
                            "othersText": "Otros Patrocinadores"
                        },
                        "confirmationDialog": {
                            "title": "Confirmación de Registro"
                        },
                        "registrationsClosedText": "Las inscripciones para este torneo no están disponibles"
                    },
                    "list": {
                        "pageTitle": "Mis Registros",
                        "emptyListMessage": "No se encontraron registros",
                        "cancelScheduleMessage": "¿Realmente deseas cancelar tu registro?",
                        "filters": {
                            "cityLabel": "Ciudad",
                            "dateLabel": "Período",
                            "statusLabel": "Estado",
                            "filterBtn": "Filtrar"
                        },
                        "table": {
                            "headers": {
                                "createdAt": 'Data',
                                "stage": "Torneo",
                                "playerClass": "Categoría",
                                "points": "Puntos",
                                "amount": "Valor",
                                "status": "Estado"
                            },
                            "items": {
                                "actions": {
                                    "cancelBtn": "Cancelar Registro"
                                }
                            }
                        }
                    }
                }
            },
            "order": {
                "routes": {
                    "index": {
                        "path": "/comandas",
                        "title": "Comandas"
                    }
                },
                "components": {
                    "list": {
                        "pageTitle": "Comandas",
                        "clubNotSelected": "Selecciona un club para buscar tus comandas",
                        "emptyListMessage": "No se encontraron clubes",
                        "generateTransactionMessage": "¿Realmente deseas generar un pago para esta comanda?",
                        "filters": {
                            "clubLabel": "Club",
                            "dateLabel": "Período",
                            "filterBtn": "Filtrar"
                        },
                        "table": {
                            "headers": {
                                "club": "Club",
                                "due_date": "Vencimiento",
                                "description": "Descripción",
                                "amount": "Valor (R$)",
                                "products": "Productos",
                                "to_pay": "Pagar",
                            },
                            "items": {
                                "actions": {
                                    "showBtn": "Detalles",
                                    "payBtn": "Generar Pago (PIX)"
                                }
                            }
                        },
                        "generatePaymentBtn": 'Generar QR Code para pago',
                        "clearSelectionBtn": 'Eliminar Selección',
                        "totalLabel": 'Valor Total'
                    },
                    "productsTable": {
                        "title": "Productos Consumidos",
                        "table": {
                            "headers": {
                                "description": "Producto",
                                "quantity": "Cant.",
                                "price": "Valor (R$)"
                            }
                        }
                    }
                }
            },
            "schedule": {
                "routes": {
                    "daily": {
                        "path": "/reservar/:slug?/:hash?",
                        "title": "Reservar Horario"
                    },
                    "invite": {
                        "path": "/convite-para-partida/:uuid",
                        "title": "Invitación para Partida"
                    },
                    "index": {
                        "path": "/mis-reservas",
                        "title": "Reservas/Partidas"
                    },
                    "show": {
                        "path": "/reserva/:id/:club_id?",
                        "title": "Reserva"
                    },
                    "invites": {
                        "path": "/mis-invites",
                        "title": "Invitaciones"
                    }
                },
                "components": {
                    "invite": {
                        "pageTitle": "Invitación para Partida",
                        "data": {
                            "invitePlayerText": "Jugador",
                            "dateText": "Fecha",
                            "courtText": "Pista",
                            "clubText": "Club",
                            "inviteAcceptBtn": "Aceptar",
                            "declineAcceptBtn": "Declinar"
                        }
                    },
                    "invites": {
                        "pageTitle": "Invitaciones",
                        "emptyListMessage": "No tienes invitaciones",
                        "table": {
                            "headers": {
                                "datetime": "Fecha/Hora",
                                "club": "Club",
                                "city": "Ciudad",
                                "court": "Pista",
                                "status": "Estado"
                            },
                            "items": {
                                "responsible": "Responsable",
                                "participant": "Participante"
                            }
                        }
                    },
                    "list": {
                        "pageTitle": "Mis Reservas/Partidas",
                        "emptyListMessage": "Aún no tienes reservas/partidas",
                        "table": {
                            "headers": {
                                "datetime": "Fecha/Hora",
                                "club": "Club",
                                "city": "Ciudad",
                                "court": "Pista",
                                "status": "Estado"
                            },
                            "items": {
                                "responsible": "Responsable",
                                "participant": "Participante",
                                "fixedSchedule": "Horario Fijo",
                                "lesson": 'Aula Separada',
                                "fixedLesson": 'Aula Fija',
                                "status": {
                                    "created": "Pendiente",
                                    "created_with_payment": "Esperando Pago",
                                    "paid": "Pago",
                                    "scheduled": "Confirmada",
                                    "scheduled_with_payment": "Pagada/Confirmada",
                                    "canceled": "Cancelada"
                                },
                                "paymentBtn": "Pagar"
                            }
                        }
                    },
                    "schedule": {
                        "pageTitle": "Reservar Horario",
                        "emptyListTitle": "Este club no tiene horarios disponibles en esta fecha",
                        "emptyListMessage": "Selecciona otra fecha o club",
                        "emptyListMessageNoClub": "No se encontraron pistas disponibles en este club, selecciona otro club o ciudad",
                        "openGlobalFilters": "Cambiar Filtros",
                        "clubPageSubTitleLabel": "Club",
                        "currentDateLabel": "Fecha de Reserva",
                        "multipleScheduleBtn": "Reservar Horarios Seleccionados",
                        "multipleScheduleClearBtn": "Limpiar Selección",
                        "multipleSelectionMaxCountErrorMessage": "No puedes seleccionar más de {count} horario(s)",
                        "multipleSelectionErrorMessage": "Solo puedes reservar horarios de una misma pista",
                        "multipleSelectionSequenceErrorMessage": "No puedes dejar horarios vacíos entre las reservas",
                        "membersScheduleText": "Contacta al club para obtener más información sobre horarios exclusivos",
                        "list": {
                            "filters": {
                                "dateLabel": "Fecha de Reserva/Partida",
                                "statusLabel": "Estado",
                                "filterBtn": "Filtrar"
                            },
                            "items": {
                                "courtPriceText": "Precio",
                                "scheduleBtn": "Reservar",
                                "scheduledText": "Horario Reservado",
                                "onlinePaymentText": "Pago en Línea",
                                "membersScheduleText": "Horario Exclusivo",
                                "selectedCoachText": "Selecciona un entrenador para ver el precio de la clase",
                                "scheduleUnavailableText": "Horarios no disponibles para reservas",
                                "lessonUnavailableText": "Horarios no disponibles para reservas de clases",
                                "lessonCoachUnavailableText": "Entrenador no disponible en este horario",
                            }
                        },
                        "dialog": {
                            "title": "Reserva de Horario",
                            "form": {
                                "timeText": "Hora",
                                "clubText": "Club",
                                "courtText": "Pista",
                                "dateText": "Fecha",
                                "hourText": "Hora",
                                "hourBetweenText": "a",
                                "courtPriceText": "Precio de la Reserva",
                                "sportText": "Selecciona el Deporte",
                                "notesLabel": "Notas"
                            }
                        }
                    },
                    "show": {
                        "pageTitle": "Datos de la Reserva",
                        "cancelScheduleMessage": "¿Realmente deseas cancelar esta reserva?",
                        "tabs": {
                            "data": {
                                "title": "Información",
                                "dateText": "Fecha",
                                "courtText": "Pista",
                                "clubText": "Club",
                                "cityText": "Ciudad",
                                "amountPaidText": "Monto Pagado",
                                "paidDateTest": "Pagado en",
                                "cancelScheduleBtn": "Cancelar Reserva",
                                "paymentBtn": "Pagar Reserva",
                                "confirmedPlayersTitle": "Jugadores Confirmados",
                                "confirmedPlayersEmptyMessage": "La reserva aún no tiene jugadores confirmados",
                                "invitedPlayersTitle": "Jugadores Invitados",
                                "invitedPlayersEmptyMessage": "Invita a tus amigos a la partida",
                                "cancelLessonBtn": "Cancelar Aula Separada",
                                "cancelLessonFixedBtn": "Cancelar Aula Fija",
                                "teacherText": "Entrenador"
                            },
                            "invite": {
                                "title": "Invitar Amigos",
                                "searchUserPlaceHolder": "Buscar jugadores...",
                                "inviteBtn": "Invitar",
                                "emptyListSearchBy": "Busca por nombre, teléfono, email o CPF",
                                "scheduleFullTitle": "Reserva Completa",
                                "scheduleFullMessage": "4 jugadores ya han confirmado su presencia en la partida"
                            }
                        }
                    }
                }
            },
            "stage": {
                "routes": {
                    "show": {
                        "path": "/torneo/:slug",
                        "title": "Torneo"
                    },
                    "matches": {
                        "path": "partidos/:date?",
                        "title": "Torneo / Partidos"
                    },
                    "pairs": {
                        "path": "inscritos/:player_class?",
                        "title": "Torneo / Inscritos"
                    },
                    "groups": {
                        "path": "grupos/:player_class?",
                        "title": "Torneo / Grupos"
                    },
                    "list": {
                        "path": "/torneos/anteriores",
                        "title": "Torneos Anteriores"
                    }
                },
                "components": {
                    "pairs": {
                        "registrationStatusCreated": "Inscripción Pendiente",
                        "registrationStatusApproved": "Inscripción Confirmada",
                        "registrationStatusQueue": "Lista de Espera",
                        "subtitleText": "Leyenda",
                        "pairsLoadingTitle": "Cargando lista de inscritos...",
                        "pairsNotAvailableTitle": "Este torneo aún no tiene inscritos",
                        "pairsNotAvailableMessage": "Realiza tu inscripción haciendo clic en el botón de abajo"
                    },
                    "groups": {
                        "groupText": "Grupo",
                        "groupsNotAvailableTitle": "Los grupos/eliminatorias de este torneo aún no están disponibles",
                        "groupsLoadingTitle": "Cargando grupos/eliminatorias...",
                        "matchesText": "Partidos",
                        "eliminatoryMatchesText": "Partidos de Eliminación",
                        "matchWithoutDatetimeText": "Horario por Definir",
                        "table": {
                            "pairName": "Pareja",
                            "singleName": "Atleta",
                            "wins": "Victorias",
                            "setsBalance": "Saldo de Sets",
                            "games": "Saldo de Juegos"
                        }
                    },
                    "matches": {
                        "searchMatchesOfPlayer": "Buscar jugador(a)...",
                        "matchesNotAvailableTitle": "No se encontraron juegos disponibles en esa fecha",
                        "matchesLoadingTitle": "Cargando partidos...",
                        "matchesPlayerNotFound": 'No encontramos juegos para este atleta',
                    },
                    "list": {
                        "pageTitle": "Torneos Anteriores",
                        "emptyListMessage": "No se encontraron torneos",
                        "showFiltersText": "Mostrar Filtros",
                        "cityLabel": "Ciudad",
                        "yearLabel": "Año"
                    }
                }
            },
            "user": {
                "routes": {
                    "register": {
                        "path": "/registro",
                        "title": "Regístrate"
                    },
                    "confirmPhone": {
                        "path": "/confirmar-telefono/:phone?/:code?",
                        "title": "Confirmar Teléfono"
                    },
                    "activateAccount": {
                        "path": '/ativar-conta/:hash?',
                        "title": 'Ativar Cuenta'
                    },
                    "confirmAccount": {
                        "path": '/confirmar-conta/:hash?',
                        "title": 'Confirmar Cuenta'
                    },
                    "show": {
                        "path": "/mi-cuenta",
                        "title": "Mi Cuenta"
                    },
                    "friends": {
                        "path": "/mis-amigos",
                        "title": "Mis Amigos"
                    }
                },
                "components": {
                    "confirmPhone": {
                        "checkCodeMessage": "Estamos verificando el código",
                        "smsConfirmationText": "Hemos enviado un SMS al teléfono proporcionado con el código de confirmación",
                        "form": {
                            "phoneLabel": "Teléfono",
                            "confirmationCodeLabel": "Código de Confirmación",
                            "confirmBtn": "Confirmar Registro"
                        }
                    },
                    "activateAccount": {
                        "checkActivation": "Estamos activando tu cuenta",
                    },
                    "confirmAccount": {
                        "checkConfirmation": "Estamos confirmando tu cuenta",
                    },
                    "form": {
                        "pageTitle": "Mi Cuenta",
                        "invalidCPFMessage": "CPF inválido",
                        "avatarText": "Mi Avatar",
                        "updateAvatarBtn": "Cambiar Avatar",
                        "updatePasswordBtn": "Cambiar Contraseña",
                        "form": {
                            "countryLabel": "País",
                            "cityLabel": "Ciudad",
                            "firstNameLabel": "Nombre",
                            "lastNameLabel": "Apellido",
                            "emailLabel": "Correo Electrónico",
                            "phoneLabel": "Teléfono",
                            "cpfLabel": "CPF",
                            "ciLabel": "CI",
                            "dniLabel": "DNI",
                            "birthDateLabel": "Fecha de Nacimiento",
                            "sexLabel": "Género",
                            "sideLabel": "Lado de Preferencia"
                        }
                    },
                    "friends": {
                        "pageTitle": "Mis Amigos",
                        "tabs": {
                            "friends": {
                                "title": "Amigos",
                                "searchFriendsPlaceholder": "Buscar amigos...",
                                "inviteSentText": "Invitación enviada",
                                "emptyListMessage": "Todavía no tienes amigos"
                            },
                            "requests": {
                                "title": "Solicitudes",
                                "acceptInviteBtn": "Aceptar",
                                "emptyListMessage": "No hay solicitudes pendientes"
                            },
                            "search": {
                                "title": "Buscar",
                                "searchUserPlaceholder": "Buscar usuarios...",
                                "inviteBtn": "Invitar",
                                "emptyListMessage": "Busca por nombre, teléfono o correo electrónico"
                            }
                        }
                    },
                    "register": {
                        "registerLoadingMessage": "Estamos verificando tus datos",
                        "form": {
                            "countryLabel": "País",
                            "nameLabel": "Nombre Completo",
                            "emailLabel": "Correo Electrónico",
                            "phoneLabel": "Teléfono",
                            "cpfLabel": "CPF",
                            "ciLabel": "CI",
                            "dniLabel": "DNI",
                            "passwordLabel": "Contraseña",
                            "storeUserBtn": "Finalizar Registro"
                        }
                    },
                    qrCodeReader: {
                      onDecode: {
                        success: {
                          title: 'Su QR Code fue validado con exito',
                        }
                      },
                      onInit: {
                        error: {
                          title: 'Ocurrio un error al iniciar la camara, verifique los permisos o actualice su navegador',
                        }
                      },
                      instruction: 'Apunte la camara al QR Code',
                    },
                }
            }
        },
        "data": {
            "locale": {
                "portuguese": "Portugués",
                "spanish": "Español"
            },
            "sex": {
                "male": "Masculino",
                "female": "Femenino",
                "notInformed": "No Informar"
            },
            "side": {
                "left": "Revés",
                "right": "Drive",
                "both": "Ambos"
            },
            "schedule_status": {
                "created": "Pendiente",
                "created_with_payment": "Esperando Pago",
                "paid": "Pago",
                "scheduled": "Confirmada",
                "canceled": "Cancelada"
            },
            "shirt_type": {
                "standard": "Normal",
                "standard_female": "Femenina",
                "tight": "Baby Look"
            },
            "registration_status": {
                "created": "Esperando Confirmación",
                "created_with_payment": "Esperando Pago",
                "paid": "Pago",
                "approved": "Confirmada",
                "queue": "En Lista de Espera",
                "canceled": "Cancelada"
            }
        },
        "validation": {
            "required": "Este campo es obligatorio",
            "invalidEmail": "Correo electrónico inválido",
            "invalidCpf": "CPF inválido",
            "minLength": "El número mínimo de caracteres para este campo es: {minLength}",
            "maxLength": "El número máximo de caracteres para este campo es: {maxLength}",
            "sameAs": "Los campos deben ser iguales",
            "invalidPhone": "Teléfono inválido"
        }
    },
    "components": {
        "general": {
            "emptyList": {
                "defaultTitle": "¡Ops!",
                "defaultMessage": "No encontramos lo que estás buscando..."
            },
            "enableNotification": {
                "enableText": "Deshabilitar",
                "disableText": "Habilitar",
                "notificationsText": "",
                "notificationPermissionUpdateText": "Las permisiones para recibir notificaciones han sido actualizadas"
            },
            "pageHeader": {
                "homePageText": "Página Inicial"
            },
            "playerList": {
                "defaultTitle": "Jugadores",
                "defaultEmptyListText": "Tu reserva todavía no tiene jugadores invitados"
            },
            "transactionStatus": {
                "created": "Pendiente",
                "approved": "Confirmada",
                "queued": "En cola",
                "canceled": "Cancelada",
                "confirmed": "Confirmada",
                "confirmedAt": "Confirmada<br>En: {pay_date}h",
                "waitingPayment": "Esperando Pago",
                "waitingConfirmation": "Pendiente",
                "doPaymentBtn": "Pagar"
            },
            "transactionPayment": {
                "text": "Utiliza el código QR o el código PIX a continuación para realizar el pago",
                "amountText": "Monto",
                "generateQrCodeText": "Generando código QR...",
                "pixCodeText": "Código PIX",
                "pixCopyCodeText": "Copiar Código PIX"
            }
        },
        "main": {
            "app": {
                "networkStatus": {
                    "activeTitle": "Conexión activa",
                    "activeMessage": "Tu conexión a Internet está funcionando",
                    "inactiveTitle": "Conexión interrumpida",
                    "inactiveMessage": "Tu conexión a Internet parece estar inactiva"
                },
                "updateAvailable": {
                    "text": "Hay una actualización disponible. Para continuar, utiliza el botón a continuación para actualizar el sistema",
                    "textUpdated": "La aplicación ha sido actualizada. ¡Disfruta!",
                    "btnAction": "Actualizar"
                },
                "deviceVerification": {
                    "email": {
                        "text": "Tu <strong>correo electrónico</strong> aún no ha sido confirmado",
                        "btnText": "Enviar correo de confirmación"
                    },
                    "phone": {
                        "text": "Tu <strong>teléfono</strong> aún no ha sido confirmado",
                        "btnCodeText": "Ya tengo un código",
                        "btnText": "Enviar mensaje de confirmación"
                    },
                    "modal": {
                        "title": "Confirmación de Teléfono",
                        "confirmCodeBtnText": "Confirmar teléfono",
                        "sendBtnText": "Enviar mensaje",
                        "emailText": "Correo electrónico",
                        "phoneText": "Teléfono",
                        "channelText": "Enviar a través de",
                        "codeText": "Ingresa el código enviado"
                    }
                }
            },
            "header": {
                "password": {
                    "changeText": "Tu contraseña ha sido cambiada",
                    "modal": {
                        "title": "Cambio de Contraseña",
                        "currentPasswordLabel": "Contraseña actual",
                        "newPasswordLabel": "Nueva contraseña",
                        "newPasswordConfirmationLabel": "Repite la nueva contraseña",
                        "saveBtn": "Cambiar contraseña"
                    }
                }
            },
            "globalFilters": {
                "title": "Filtros Globales"
            },
            "headerNotifications": {
                "title": "Notificaciones",
                "emptyListMessage": "No hay notificaciones disponibles"
            },
            "sidebar": {
                "subTitleClubLabel": "Club",
                "selectCity": {
                    "label": "Ciudad",
                    "emptyList": "Ciudades no disponibles"
                },
                "selectClub": {
                    "label": "Club",
                    "emptyList": "Clubes no disponibles"
                },
                "menu": {
                    "sections": {
                        "clubs": "Clubes",
                        "stages": "Torneos",
                        "user": "Usuario"
                    },
                    "items": {
                        "main": "Página Inicial",
                        "authLogin": "Iniciar Sesión",
                        "userRegister": "Registrarse",
                        "scheduleDaily": "Reservar Cancha",
                        "scheduleIndex": "Reservas/Partidas",
                        "scheduleInvites": "Invitaciones de Juegos",
                        "membershipIndex": "Afiliaciones",
                        "orderIndex": "Comandas",
                        "registrationIndex": "Mis Inscripciones",
                        "registrationStore": "Nueva Inscripción",
                        "stageList": "Torneos Anteriores",
                        "userFriends": "Amigos",
                        "userShow": "Mi Cuenta",
                        "userQrCode": "Validar el QR Code",
                        "termsConditions": "Términos y Condiciones",
                        "privacyPolicies": "Políticas de Privacidad",
                        "contact": "Contáctanos",
                        "userLogout": "Cerrar Sesión"
                    }
                }
            }
        }
    }
}