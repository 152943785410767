import moment from 'moment'
import 'moment/locale/pt-br'
import * as MESSAGES from '../app/messages'

import * as CPF from '@utils/cpf'

const validCPF = (cpf) => CPF.validate(cpf)

// Set moment locale
moment.locale('pt-BR')

// Formatos de datas
const dateFormatBr = 'DD/MM/YYYY'
const dateFormatEn = 'YYYY-MM-DD'
const dateTimeFormatBr = 'DD/MM/YYYY HH:mm:ss'
const dateTimeFormatEn = 'YYYY-MM-DD HH:mm:ss'
const dateTimeFormatBrStamp = 'DD/MM/YYYYTHH:mm:ss'
const dateTimeFormatEnStamp = 'YYYY-MM-DDTHH:mm:ss'

/**
 * Função que verifica se o formato passado de data é válido
 * Caso sim, retorna uma instância do moment
 *
 * @param date
 * @returns moment
 */
const getMomentInstance = function (date) {
    let momentInstance = null
    if (moment(date, dateFormatBr, true).isValid()) {
        momentInstance = moment(date, dateFormatBr, true)
    } else if (moment(date, dateFormatEn, true).isValid()) {
        momentInstance = moment(date, dateFormatEn, true)
    } else if (moment(date, dateTimeFormatBr, true).isValid()) {
        momentInstance = moment(date, dateTimeFormatBr, true)
    } else if (moment(date, dateTimeFormatEn, true).isValid()) {
        momentInstance = moment(date, dateTimeFormatEn, true)
    } else if (moment(date, dateTimeFormatBrStamp, true).isValid()) {
        momentInstance = moment(date, dateTimeFormatBrStamp, true)
    } else if (moment(date, dateTimeFormatEnStamp, true).isValid()) {
        momentInstance = moment(date, dateTimeFormatEnStamp, true)
    } else {
        console.warn('Formato inválido', date)
    }
    return momentInstance
}

export default {
    methods: {
        /**
         * Retorna o datetime corrente do S.O.
         * @returns moment
         */
        momentNow: function () {
            return moment()
        },
        /**
         *
         * @param date
         * @returns {moment}
         */
        dateMomentInstance: function (date) {
            return getMomentInstance(date)
        },
        /**
         * Função para formatar datas
         *
         * @param date
         * @param format Formato de retorno (br ou en)
         * @returns string
         */
        dateFormat: function (date, format) {
            if (date === undefined || date === null || date.length < 1) {
                return ''
            }
            let momentInstance = getMomentInstance(date)
            if (!momentInstance) {
                return ''
            }
            format = format || 'br'
            return momentInstance.format(format === 'br' ? dateFormatBr : dateFormatEn)
        },
        /**
         * Função para formatar datetime
         *
         * @param datetime
         * @param format Formato de retorno (br ou en)
         * @param short Encurtar hora (Ex.: 15:30 ou 15:30:00)
         * @returns string
         */
        dateTimeToTimeFormat: function (datetime, short) {
            if (datetime === undefined || datetime === null || datetime.length < 1) {
                return ''
            }
            let momentInstance = getMomentInstance(datetime)
            if (!momentInstance) {
                return ''
            }
            short = short || false
            let resultFormat = (short ? 'HH:mm' : 'HH:mm:ss')
            return momentInstance.format(resultFormat)
        },
        /**
         * Função para formatar datetime
         *
         * @param datetime
         * @param format Formato de retorno (br ou en)
         * @param short Encurtar hora (Ex.: 15:30 ou 15:30:00)
         * @returns string
         */
        dateTimeFormat: function (datetime, format, short) {
            if (datetime === undefined || datetime === null || datetime.length < 1) {
                return ''
            }
            let momentInstance = getMomentInstance(datetime)
            if (!momentInstance) {
                return ''
            }
            format = format || 'br'
            short = short || false
            let resultFormat = (format === 'br' ? dateFormatBr : dateFormatEn) + ' ' + (short ? 'HH:mm' : 'HH:mm:ss')
            return momentInstance.format(resultFormat)
        },
        /**
         * Função para formatar times
         *
         * @param time
         * @param short Encurtar hora (Ex.: 15:30 ou 15:30:00)
         * @returns string
         */
        timeFormat: function (time, short) {
            if (time === undefined || time === null || time.length < 1) {
                return ''
            }
            let momentInstance = getMomentInstance(time)
            if (!momentInstance) {
                return ''
            }
            short = short || false
            return momentInstance.format(short ? 'HH:mm' : 'HH:mm:ss')
        },
        dayMonthFormat: function (date, format) {
            if (date === undefined || date === null || date.length < 1) {
                return ''
            }
            format = format || 'br'
            if (format === 'br') {
                return moment(date, 'YYYY-MM-DD').format('DD/MM')
            } else if (format === 'en') {
                return moment(date, 'DD/MM/YYYY').format('MM-DD')
            }
        },
        dateFirstDayOfWeekDate: function () {
            return moment().format('YYYY-MM-DD')
        },
        dateLastDayOfWeekDate: function () {
            return moment().endOf('week').format('YYYY-MM-DD')
        },
        /**
         * Função para converter valor númerio string br (1.500,50) para float/decimal
         *
         * @param number
         * @returns float
         */
        currencyBrToEn: function (number) {
            if (number !== undefined && number !== false) {
                let newValue = number.replace('R$', '').replace('r$', '')
                if (newValue.indexOf(',') > -1) {
                    let replaced = newValue.replace(/[.]/g, '')
                    newValue = replaced.replace(',', '.')
                }
                return parseFloat(newValue)
            }
            return number
        },
        /**
         * Função para converter valor númerico float (1500.50) para string br (1.500,50)
         *
         * @param number
         * @returns string
         */
        currencyEnToBr: function (number) {
            if (number === 0) {
                return '0,00'
            }
            if (number !== undefined && number !== null) {
                return this.numberFormat(number, 2, ',', '.')
            }
            return number
        },
        /**
         * Função que simula o number_format do PHP
         *
         * @param number
         * @param decimals
         * @param decPoint
         * @param thousandsSep
         * @returns string
         */
        numberFormat: function (number, decimals, decPoint, thousandsSep) {
            number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
            let n = !isFinite(+number) ? 0 : +number
            let prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
            let sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
            let dec = (typeof decPoint === 'undefined') ? '.' : decPoint
            let s = ''

            let toFixedFix = function (n, prec) {
                let k = Math.pow(10, prec)
                return '' + (Math.round(n * k) / k).toFixed(prec)
            }

            s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
            if (s[0].length > 3) {
                s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
            }
            if ((s[1] || '').length < prec) {
                s[1] = s[1] || ''
                s[1] += new Array(prec - s[1].length + 1).join('0')
            }

            return s.join(dec)
        },
        /**
         * Função para descartar qualquer caracter que não for número de uma string
         *
         * @param str
         * @returns string
         */
        onlyNumbers(str) {
            if (str === undefined || str === null) {
                return str
            }
            if (typeof str !== 'string') {
                str = str.toString()
            }
            return str.replace(/\D+/g, '')
        },
        /**
         * Função para arredondar valor decimal
         *
         * @param value
         * @param decimals
         * @returns {number}
         */
        roundLower: function (value, decimals) {
            let aux = Math.pow(10, decimals)
            return Math.floor(value * aux) / aux
        },
        /**
         * Função que converte bytes para string
         *
         * @param bytes
         * @returns {string}
         */
        bytesToSize: function (bytes) {
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
            if (bytes === 0) return '0 Bytes'
            if (bytes === 1) return '1 Byte'
            const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
            if (i === 0) return `${bytes} ${sizes[i]}`
            return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
        },
        validationMessageField(field, types, value = null, charsLength = null) {
            types = types || ['required']
            const errors = []
            if (!field.$dirty) return errors
            if (types.indexOf('required') !== -1) {
                !field.required && errors.push(MESSAGES.VALIDATION_REQUIRED)
            }
            if (types.indexOf('validEmail') !== -1) {
                !field.email && errors.push(MESSAGES.VALIDATION_EMAIL)
            }
            if (types.indexOf('minLength') !== -1) {
                !field.minLength && errors.push(MESSAGES.VALIDATION_MINLENGTH(field.$params.minLength.min))
            }
            if (types.indexOf('maxLength') !== -1) {
                !field.maxLength && errors.push(MESSAGES.VALIDATION_MAXLENGTH(field.$params.maxLength.max))
            }
            if (value && types.indexOf('validCPF') !== -1) {
                !validCPF(value) && errors.push(MESSAGES.VALIDATION_CPF)
            }
            if (value && types.indexOf('validPhone') !== -1) {
                value.length < charsLength && errors.push(MESSAGES.VALIDATION_PHONE)
            }
            if (types.indexOf('sameAs') !== -1) {
                !field.sameAs && errors.push(MESSAGES.VALIDATION_EQUAL)
            }
            return errors
        },
        isMobile: function () {
            let vm = this
            return {
                Android: function () {
                    return navigator.userAgent.match(/Android/i)
                },
                BlackBerry: function () {
                    return navigator.userAgent.match(/BlackBerry/i)
                },
                iOS: function () {
                    return navigator.userAgent.match(/iPhone|iPad|iPod/i)
                },
                Opera: function () {
                    return navigator.userAgent.match(/Opera Mini/i)
                },
                Windows: function () {
                    return navigator.userAgent.match(/IEMobile/i)
                },
                any: function () {
                    return (vm.isMobile().Android() || vm.isMobile().BlackBerry() || vm.isMobile().iOS() || vm.isMobile().Opera() || vm.isMobile().Windows())
                }
            }
        },
        getFirstAndLastName: function (name) {
            if (!name) {
                return {
                    firstName: '',
                    lastName: ''
                }
            }
            const splitName = name.split(' ')
            return {
                firstName: splitName.splice(0, 1)[0],
                lastName: (splitName.length > 2 ? splitName.splice((splitName.length - 1) * -1) : splitName.splice(0, 2)).join(' ')
            }
        },
        dateToShortDayMonth(date, treeChars = false) {
            return moment(date, 'YYYY-MM-DD').format(`D/MMM${treeChars ? '' : 'M'}`)
        },
        /**
         * @param base64String
         * @returns {Uint8Array}
         */
        urlBase64ToUint8Array(base64String) {
            const padding = '='.repeat((4 - base64String.length % 4) % 4)
            const base64 = (base64String + padding)
                .replace(/-/g, '+')
                .replace(/_/g, '/')
            const rawData = window.atob(base64)
            const outputArray = new Uint8Array(rawData.length)
            for (let i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i)
            }
            return outputArray
        },
        formatStageDates(dates) {
            const firstDate = dates[0]
            const lastDate = dates[dates.length - 1]
            return `<span class="text">de</span><span>${this.dateToShortDayMonth(firstDate)}</span><span class="text">até</span><span>${this.dateToShortDayMonth(lastDate)}</span>`
        },
        stageRouteSlug(id, tenantId, slug) {
            return `${slug}--${tenantId}-${id}`
        },
        slugify(str, separator = '-') {
            return String(str)
                .normalize('NFKD') // split accented characters into their base characters and diacritical marks
                .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
                .trim() // trim leading or trailing whitespace
                .toLowerCase() // convert to lowercase
                .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
                .replace(/\s+/g, separator) // replace spaces with hyphens
                .replace(/-+/g, separator) // remove consecutive hyphens
        },
        countryPhoneMask(countryCode) {
            switch (countryCode) {
                case 'URY':
                    return '## ### ###'

                case 'ARG':
                    return '# ## ####-####'

                case 'PRY':
                    return '### ######'

                default:
                    return '## #####-####'
            }
        },
        getBoundingBox(lat, lon, radius = 50) {
            const latRange = radius / 111.045; // Approximate km to degrees conversion
            const lonRange = radius / (111.045 * Math.cos(lat * Math.PI / 180));

            return {
                minLat: lat - latRange,
                maxLat: lat + latRange,
                minLon: lon - lonRange,
                maxLon: lon + lonRange,
            };
        },
        haversineDistance(lat1, lon1, lat2, lon2) {
            const R = 6371; // Earth radius in km
            const dLat = (lat2 - lat1) * Math.PI / 180;
            const dLon = (lon2 - lon1) * Math.PI / 180;
            const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
                Math.sin(dLon / 2) * Math.sin(dLon / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            return R * c; // Distance in km
        }
    }
}